import { type Namespace } from 'i18next'

export type Language = 'vi' | 'ja'

export const defaultLanguage: Language = 'ja'
export const availableLanguages: Language[] = [defaultLanguage, 'vi']
// eslint-disable-next-line @typescript-eslint/naming-convention
export const defaultNS: Namespace = 'common'
export const localeCookieName = 'NEXT_LOCALE'
export const fallbackLng: Language = 'ja'

export const getI18nextOptions = (lng: Language = defaultLanguage, ns: Namespace = defaultNS) => ({
  supportedLngs: availableLanguages,
  defaultLanguage,
  lng,
  fallbackNS: defaultNS,
  defaultNS,
  ns,
  fallbackLng,
})
