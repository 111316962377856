'use client'

import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useContext,
  useState,
} from 'react'

interface CacheControlContext {
  facilityId: string
}

export const CacheControlContext = createContext<CacheControlContext>({
  facilityId: '',
})

export const SetCacheControlContext = createContext<Dispatch<SetStateAction<CacheControlContext>>>(
  () => {}
)

export function CacheControlContextProvider({ children }: PropsWithChildren) {
  const [value, setValue] = useState<CacheControlContext>({ facilityId: '' })
  return (
    <CacheControlContext.Provider value={value}>
      <SetCacheControlContext.Provider value={setValue}>{children}</SetCacheControlContext.Provider>
    </CacheControlContext.Provider>
  )
}

export const useCacheControlContextValue = () => useContext(CacheControlContext)
export const useSetCacheControlContextValue = () => useContext(SetCacheControlContext)
