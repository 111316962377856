'use client'

import { type Analytics, getAnalytics, isSupported } from 'firebase/analytics'
import { getApps, initializeApp } from 'firebase/app'
import { type AppCheck, initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'
import { getAuth } from 'firebase/auth'
import { type FirebasePerformance, getPerformance } from 'firebase/performance'

import { env } from '@/env'

declare global {
  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined
}

export const createFirebaseServices = () => {
  const clientCredentials = {
    apiKey: env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseURL: env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    projectId: env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: env.NEXT_PUBLIC_FIREBASE_APP_ID,
    // measurementId: env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  }

  const app = !getApps().length ? initializeApp(clientCredentials) : getApps()[0]

  let analytics: Analytics | undefined
  let performance: FirebasePerformance | undefined
  if (typeof window !== 'undefined') {
    isSupported()
      .then((_) => (performance = getPerformance(app)))
      .catch((e) =>
        console.warn('FirebasePerformance is not supported in this environment.', e.message)
      )

    if ('measurementId' in clientCredentials) {
      isSupported()
        .then((_) => (analytics = getAnalytics(app)))
        .catch((e) => console.warn('Analytics is not supported in this environment.', e.message))
    }
  }

  let appCheck: AppCheck | undefined
  if (typeof document !== 'undefined') {
    if (env.NEXT_PUBLIC_ENVIRONMENT === 'local') {
      self.FIREBASE_APPCHECK_DEBUG_TOKEN = true
    }

    // scsk環境のみ環境変数に登録されているデバッグトークンを使用するようにする
    if (env.NEXT_PUBLIC_ENVIRONMENT === 'scsk') {
      self.FIREBASE_APPCHECK_DEBUG_TOKEN = env.NEXT_PUBLIC_FIREBASE_APPCHECK_DEBUG_TOKEN
    }

    appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaEnterpriseProvider(
        env.NEXT_PUBLIC_RE_CAPTCHA_ENTERPRISE_SITE_KEY ?? ''
      ),
      isTokenAutoRefreshEnabled: true,
    })
  }

  return { auth: getAuth(app), performance, analytics, appCheck }
}
