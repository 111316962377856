'use client'

import { createContext, type PropsWithChildren } from 'react'

import { type Language } from '../config'

export const ClientTranslationContext = createContext<{
  lang: Language
}>({} as { lang: Language })

export function ClientTranslationProvider(props: PropsWithChildren & { lang: Language }) {
  const { children, ...ctx } = props
  return (
    <ClientTranslationContext.Provider value={ctx}>{children}</ClientTranslationContext.Provider>
  )
}
