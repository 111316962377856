'use client'

import { createFirebaseServices } from '@/lib/firebase/client'

const { performance } = createFirebaseServices()
if (performance) performance

export function FirebasePerformance() {
  return null
}
