'use client'

import { createFirebaseServices } from '@/lib/firebase/client'

const { analytics } = createFirebaseServices()
if (analytics) analytics

export function FirebaseAnalytics() {
  return null
}
