'use client'

import {
  type ComponentPropsWithoutRef,
  createContext,
  type ElementRef,
  forwardRef,
  type HTMLAttributes,
  type PropsWithChildren,
  type ReactNode,
  useContext,
  useId,
  useMemo,
} from 'react'
import { useFormStatus } from 'react-dom'
import {
  type Control,
  Controller,
  type ControllerProps,
  type FieldPath,
  type FieldPathValues,
  type FieldValues,
  FormProvider,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import * as LabelPrimitive from '@radix-ui/react-label'
import { Slot } from '@radix-ui/react-slot'
import { type i18n } from 'i18next'

import { cn, Flex, Loader, Typography } from '@/components'

const Form = FormProvider

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  name: TName
}

const FormFieldContext = createContext<FormFieldContextValue>({} as FormFieldContextValue)

function FormField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ ...props }: ControllerProps<TFieldValues, TName>) {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  )
}

const useFormField = () => {
  const fieldContext = useContext(FormFieldContext)
  const itemContext = useContext(FormItemContext)
  const { getFieldState, formState } = useFormContext()

  const fieldState = getFieldState(fieldContext.name, formState)

  if (!fieldContext) {
    throw new Error('useFormField should be used within <FormField>')
  }

  const { id } = itemContext

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  }
}

type FormItemContextValue = {
  id: string
}

const FormItemContext = createContext<FormItemContextValue>({} as FormItemContextValue)

const FormItem = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & { orientation?: 'horizontal' | 'vertical' }
>(({ className, orientation = 'vertical', ...props }, ref) => {
  const id = useId()

  return (
    <FormItemContext.Provider value={{ id }}>
      <div
        className={cn(
          {
            'space-y-2': orientation === 'vertical',
            'flex items-start space-x-1.5 space-y-0': orientation === 'horizontal',
          },
          className
        )}
        ref={ref}
        {...props}
      />
    </FormItemContext.Provider>
  )
})
FormItem.displayName = 'FormItem'

const FormLabel = forwardRef<
  ElementRef<typeof LabelPrimitive.Root>,
  ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
  const { error, formItemId } = useFormField()

  return (
    <LabelPrimitive.Label
      className={cn(
        'text-xl leading-7 text-input-label font-bold select-none',
        error && '!text-danger',
        className
      )}
      htmlFor={formItemId}
      ref={ref}
      {...props}
    />
  )
})
FormLabel.displayName = 'FormLabel'

const FormControl = forwardRef<ElementRef<typeof Slot>, ComponentPropsWithoutRef<typeof Slot>>(
  (props, ref) => {
    const { error, formItemId, formDescriptionId, formMessageId } = useFormField()
    return (
      <Slot
        aria-describedby={!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`}
        aria-invalid={!!error}
        id={formItemId}
        ref={ref}
        {...props}
      />
    )
  }
)
FormControl.displayName = 'FormControl'

const FormDescription = forwardRef<HTMLParagraphElement, HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => {
    const { formDescriptionId } = useFormField()

    return (
      <p
        className={cn('text-sm text-muted-foreground', className)}
        id={formDescriptionId}
        ref={ref}
        {...props}
      />
    )
  }
)
FormDescription.displayName = 'FormDescription'

const FormMessage = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement> & { i18n?: i18n }
>(({ className, children, i18n, ...props }, ref) => {
  const { error, formMessageId } = useFormField()
  let body
  const errorMessage: any = error?.message ?? error?.root?.message
  if (i18n) {
    body = errorMessage?.key
      ? i18n.t(`validation:${errorMessage.key}`, { ...(errorMessage?.params ?? {}) }).toString()
      : errorMessage
        ? String(errorMessage)
        : children
  } else {
    body = errorMessage ? String(errorMessage) : children
  }

  if (!body) {
    return null
  }

  return (
    <p
      className={cn('text-xs font-medium text-danger', className)}
      id={formMessageId}
      ref={ref}
      {...props}
    >
      {body}
    </p>
  )
})
FormMessage.displayName = 'FormMessage'

interface IFormWatcher<T extends FieldValues = FieldValues> {
  fields: FieldPath<T>[]
  control: Control<T>
  defaultValue?: any
  children?: (watchFields: FieldPathValues<T, readonly FieldPath<T>[]>) => ReactNode
  render?: (watchFields: FieldPathValues<T, readonly FieldPath<T>[]>) => ReactNode
}

function FormWatcher<T extends FieldValues>(props: IFormWatcher<T>) {
  const FormWatcherContext = useMemo(
    () => createContext<FieldPathValues<T, readonly FieldPath<T>[]>>([]),
    []
  )
  const { children, render, control, fields, defaultValue } = props
  const watcher = useWatch({
    control,
    name: fields,
    defaultValue,
  })

  return (
    <FormWatcherContext.Provider value={watcher as any}>
      <FormWatcherContext.Consumer>
        {render ? render : children ? children : () => null}
      </FormWatcherContext.Consumer>
    </FormWatcherContext.Provider>
  )
}

function FormSection({ children, className }: PropsWithChildren<{ className?: string }>) {
  return <section className={cn('grid gap-6', className)}>{children}</section>
}

function FormSectionTitle({
  title,
  tag,
}: {
  title: string
  tag?: false | 'required' | 'optional'
}) {
  return (
    <Flex gap="1">
      <Typography
        className="border-l-3 border-accent-foreground pl-2 text-lg leading-none"
        weight="bold"
      >
        {title}
      </Typography>
      {tag ? (
        <Typography
          className={cn('text-xs leading-5', {
            'text-danger': tag === 'required',
            'text-info': tag === 'optional',
          })}
          weight="bold"
        >
          {tag === 'required' ? '※必須' : '※任意'}
        </Typography>
      ) : null}
    </Flex>
  )
}

export function FormLoader() {
  const { pending } = useFormStatus()
  return <Loader show={pending} />
}

export {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormSection,
  FormSectionTitle,
  FormWatcher,
  useFormField,
}
