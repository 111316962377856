'use client'

import { type PropsWithChildren } from 'react'

import { ClientTranslationProvider } from '@/app/i18n/client/context'
import { defaultLanguage, type Language } from '@/app/i18n/config'

export function ProvidersWrapper({
  children,
  lang = defaultLanguage,
}: PropsWithChildren<{ lang: Language }>) {
  return <ClientTranslationProvider lang={lang}>{children}</ClientTranslationProvider>
}
