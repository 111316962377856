'use client'

import Image from 'next/image'

import { Flex } from '@/components'

export function AppSplash() {
  return (
    <Flex align="center" className="z-50 h-screen w-screen bg-primary" justify="center">
      <Image
        alt="Loading..."
        className="animate-splash"
        height={89}
        priority
        src="/logo-invert.svg"
        width={48}
      />
    </Flex>
  )
}
