import { createEnv } from '@t3-oss/env-nextjs'
import { z } from 'zod'

export const env = createEnv({
  // server: {},
  client: {
    NEXT_PUBLIC_ENVIRONMENT: z.enum(['local', 'dev', 'prd', 'stg', 'str', 'scsk', 'inf']),
    NEXT_PUBLIC_API_URL: z.string().url(),
    NEXT_PUBLIC_FIREBASE_API_KEY: z.string(),
    NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: z.string(),
    NEXT_PUBLIC_FIREBASE_DATABASE_URL: z.string(),
    NEXT_PUBLIC_FIREBASE_PROJECT_ID: z.string(),
    NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET: z.string(),
    NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID: z.string(),
    NEXT_PUBLIC_FIREBASE_APP_ID: z.string(),
    NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID: z.string(),
    NEXT_PUBLIC_RE_CAPTCHA_ENTERPRISE_SITE_KEY: z.string(),
    NEXT_PUBLIC_FIREBASE_APPCHECK_DEBUG_TOKEN: z.string(),
    NEXT_PUBLIC_SHIFT_APP_URL: z.string(),
  },
  experimental__runtimeEnv: {
    NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
    NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL,
    NEXT_PUBLIC_FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    NEXT_PUBLIC_FIREBASE_DATABASE_URL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    NEXT_PUBLIC_FIREBASE_PROJECT_ID: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    NEXT_PUBLIC_FIREBASE_APP_ID: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
    NEXT_PUBLIC_RE_CAPTCHA_ENTERPRISE_SITE_KEY:
      process.env.NEXT_PUBLIC_RE_CAPTCHA_ENTERPRISE_SITE_KEY,
    NEXT_PUBLIC_FIREBASE_APPCHECK_DEBUG_TOKEN:
      process.env.NEXT_PUBLIC_FIREBASE_APPCHECK_DEBUG_TOKEN,
    NEXT_PUBLIC_SHIFT_APP_URL: process.env.NEXT_PUBLIC_SHIFT_APP_URL,
  },
})

export const isPrd = env.NEXT_PUBLIC_ENVIRONMENT === 'prd'
